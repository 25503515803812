
<div *ngIf="!control && bas.envtest" class="fgc-red">
  control is undefined
</div>
<nz-form-item 
  [formGroup]="form" 
  [class]="''
    + 'bt-form-item'
    + ' fi-' + control.controlType 
    + ' fi-key-' + control.key 
    + ' ' + (control.data.css || '')
    "
  [class.fi-nomargin]="control.data.nomargin || options.nomargin"
  [class.fi-checkboxMarginTop]="control.controlType == 'checkbox' && (options.checkboxMarginTop || control.data.checkboxMarginTop) && !options.hideLabel"
  
  
  [class.fi-nolabel]="
       !(control.controlType != 'checkbox' 
    && control.controlType != 'upload'  
    && control.controlType != 'checkbox-list' 
    && (control.mk || control.label)
    && !options.hideLabel)"

  *ngIf="control && control.controlType != 'hidden'  && (control.show === undefined || control.show(control))"
  >
  
  <nz-form-label 
    *ngIf="control.controlType != 'checkbox' 
      && control.controlType != 'upload'  
      && control.controlType != 'checkbox-list' 
      && (control.mk || control.label)
      && !options.hideLabel" 
    [attr.for]="control.key"
 
    [class.text-center]="control.data.alignCenter"
    >
    <!--    [class.text-right]="control.controlType == 'label' && control.data.alignRight" -->
    {{ control.mk ? (control.mk | actrans) : control.label }}

    <span *ngIf="options.showRequiredStar && control.required">&nbsp;*</span>

    <i 
    class="mkInfoIcon"
      *ngIf="control.mkInfo"
      nz-icon [nzType]="'info-circle'" 
      nz-tooltip 
      [nzTooltipTitle]="mkInfoTemplate" 
      >
    <!--  [nzTooltipTitle]="control.mkInfo | actrans | str"  -->
      <ng-template #mkInfoTemplate>
        <span class="text-sm" [innerHtml]="control.mkInfo | actrans"></span>
      </ng-template>
    </i>


    
  </nz-form-label>
  <!-- <div *ngIf="bas.envtest">
    (controlType: {{ control.controlType  }})
 </div> -->

  <nz-form-control>
    <!--  [nzSm]="14" [nzXs]="24" -->
  
    <nz-input-group 
     *ngIf="control.controlType == 'input'"  
     [nzSuffix]="control.data.suffix" [nzPrefix]="control.data.prefix">
      
    <input nz-input
    
      [formControlName]="control.key" 
      [placeholder]="control.placeholder | actrans | str"
      [id]="control.key" 
      [type]="control.type" 
      [nzSize]="control.size ? (control.size == 'small' ? 'small' : (control.size == 'large' ? 'large' : 'default') ) : 'default'" 
      [required]="control.required"  
      [readonly]="control.data.readOnly"
      [min]="control.data.min !== undefined ? control.data.min : undefined"
      [max]="control.data.max !== undefined ? control.data.max : undefined"
      [value]="control.value"
      (change)="onChange($event)"
      (keyup)="onKeyUp($event)"

       />


    </nz-input-group>

    <nz-input-group 
      *ngIf="control.controlType == 'input-autocomplete'"  
      [nzSuffix]="control.data.suffix" [nzPrefix]="control.data.prefix">
      
    <input nz-input
    
      [formControlName]="control.key" 
      [placeholder]="control.placeholder | actrans | str"
      [id]="control.key" 
      [type]="control.type" 
      [nzSize]="control.size ? (control.size == 'small' ? 'small' : (control.size == 'large' ? 'large' : 'default') ) : 'default'" 
      [required]="control.required"  
      [readonly]="control.data.readOnly"
      [min]="control.data.min !== undefined ? control.data.min : undefined"
      [max]="control.data.max !== undefined ? control.data.max : undefined"
      [value]="control.value"
      (change)="onChange($event)"
      [nzAutocomplete]="inputAutocomplete"
      (ngModelChange)="onAutocompleteInput($event, control)"

        />
        <!-- <nz-autocomplete [nzDataSource]="control.data.autocomplete?.options || []" nzBackfill #inputAutocomplete></nz-autocomplete> -->
      <nz-autocomplete #inputAutocomplete>
        <nz-auto-option   *ngFor="let opt of control.data.autocomplete?.options || []"  [nzValue]="opt.label || opt.value || opt">{{ opt.label || opt.value || opt }}</nz-auto-option>
      </nz-autocomplete>

    </nz-input-group>

    <div *ngIf="control.controlType == 'number-range'"  >
      <input type="hidden" [formControlName]="control.key" [id]="control.key"  />
      <input nz-input

      [type]="'number'" 
      [required]="control.required"  
      [disabled]="control.disabled"
      [min]="control.data.min !== undefined ? control.data.min : undefined"
      [max]="control.data.max !== undefined ? control.data.max : undefined"
      [value]="control.fc?.value[0]"
      (change)="onNumberRangeChange(0, $event)"
       />
       
      <span class="range-separator">
        -
      </span>
      <input nz-input

      [type]="'number'" 
      [required]="control.required"  
      [disabled]="control.disabled"
      [min]="control.data.min !== undefined ? control.data.min : undefined"
      [max]="control.data.max !== undefined ? control.data.max : undefined"
      [value]="control.fc?.value[1]"
      (change)="onNumberRangeChange(1, $event)"
       />
  
       <!--
               [placeholder]="control.placeholder"
                     [placeholder]="control.placeholder"
       -->

    </div>

    <nz-input-number
      *ngIf="control.controlType == 'input-number'" 
      [formControlName]="control.key" 
      [nzPlaceHolder]="control.placeholder | actrans | str"
      [id]="control.key" 

      [required]="control.required"  
      [nzDisabled]="control.disabled"
      
      (change)="onChange($event)"
      (ngModelChange)="onChange($event)"
       [nzMin]="control.data.min !== undefined ? control.data.min : undefined"
       [nzMax]="control.data.max !== undefined ? control.data.max :  undefined"
       [nzStep]="control.data.step || 1"
       [nzPrecision]="control.data.precision"
 
       [nzFormatter]="numberFormatter"
       [nzParser]="numberParser"

       [class.align-right]="control.data.alignRight"

     ></nz-input-number>

     <!-- [ngModel]="control.value" 
     style="height: 80px;"  
     -->

  
    <textarea nz-input
      *ngIf="control.controlType == 'textarea'" 
      [formControlName]="control.key"  
      [placeholder]="control.placeholder | actrans | str"
      [id]="control.key"  
      [required]="control.required" 
    
      autoGrow
     
      [class.textarea-json]="control.data.json"
      [class.textarea-code]="control.data.code"
      [style.height.px]="control.data.height || 80"
      [value]="control.value"
      (change)="onChange($event)"
       ></textarea>


    <nz-textarea-count 
       *ngIf="control.controlType == 'textarea-count'" [nzMaxCharacterCount]="control.data.max">


      <textarea nz-input
       [formControlName]="control.key"  
        [placeholder]="control.placeholder | actrans | str"
        [id]="control.key"  
        [required]="control.required" 
      
        autoGrow
      
        [style.height.px]="control.data.height || 60"
        [value]="control.value"
        (change)="onChange($event)"
      ></textarea>
    </nz-textarea-count>

       
     <div
     *ngIf="control.controlType == 'tree-select'" 
     >
       <nz-tree-select
        [id]="control.key"  
        [formControlName]="control.key"  
        [nzPlaceHolder]="control.placeholder | actrans | str"
        [nzMaxTagCount]="control.data.maxTagCount || 3"
        [nzMaxTagPlaceholder]="omittedPlaceHolder"
        [nzNodes]="(control.optionsList || control.options())"
        [nzDefaultExpandAll]="control.data.defaultExpandAll"
        [nzAllowClear]="control.data.allowClear"
        [nzVirtualHeight]="control.data.virtualHeight"

        [nzMultiple]="control.data.multiple"
        (ngModelChange)="onModelChange($event)"
      ></nz-tree-select>
      <ng-template #omittedPlaceHolder let-omittedValues>og {{ omittedValues.length }} flere...</ng-template> <!-- TODO:text -->
    
      <!--
 style="width: 250px"
   
      -->
    </div>

    <quill-editor
     *ngIf="control.controlType == 'richtext'" 
     [formControlName]="control.key"  
     [placeholder]="control.placeholder | actrans | str"
     [modules]="quillModules"
     (onBlur)="onEditorBlur($event)"
     [debounceTime]="500"
     #quillEditor
      ></quill-editor>
      <!-- (onContentChanged)="onContentChanged($event)"-->


    <nz-select 
      *ngIf="control.controlType == 'select'"
      #controlSelect
      [id]="control.key" 
      [formControlName]="control.key" 
      [nzPlaceHolder]="control.placeholder | actrans | str"
      [nzBackdrop]="control.data.backdrop"
      [nzDisabled]="control.disabled"
      [nzShowSearch]="control.data.showSearch === undefined || control.data.showSearch === true"
      [nzMode]="control.selectMode || 'default'"
      [(nzOpen)]="control.data.open"
      (ngModelChange)="onModelChange($event, controlSelect)"
      >
      <nz-option 
       *ngIf="control.optionsAllowEmpty" 
        nzValue="" 
        [nzLabel]="control.optionsEmptyLabel | actrans | str" 
      ></nz-option>
      <nz-option 
       *ngFor="let opt of (control.optionsList || control.options())" 
        [nzValue]="control.optionsFieldValue === 'OBJECT' ? opt : opt[control.optionsFieldValue]" 
        [nzLabel]="control.optionsFieldLabel === 'OBJECT' ? opt : opt[control.optionsFieldLabel] | actrans | str" 
        [nzDisabled]="opt.disabled"
      ></nz-option>

    </nz-select>

    
    <div  *ngIf="control.controlType == 'checkbox-list'" >
      <div >
        {{ control.mk ? (control.mk | actrans) : control.label }}
        <span *ngIf="options.showRequiredStar && control.required">&nbsp;*</span>
      </div>
    
      <!--
               [id]="control.key" 
        [formControlName]="control.key" 
        (ngModelChange)="onModelChange($event)"
      -->
      <nz-checkbox-wrapper
        
 
        (nzOnChange)="onCheckboxListChange($event)"
        >
        <div nz-row>
          <div *ngFor="let opt of (control.optionsList || control.options())"  nz-col nzSpan="24">
            <label nz-checkbox [nzValue]="opt[control.optionsFieldValue]" >
              {{ opt[control.optionsFieldLabel] | actrans }}
            </label>
          </div>
        </div>
      </nz-checkbox-wrapper>
      <div nz-typography nzType="danger" *ngIf="!isValid">
        {{ 'app.lib.error.form.required' | actrans }}
      </div>
    </div>

    <nz-radio-group 
      *ngIf="control.controlType == 'radio-button'"   
      [id]="control.key" 
      [formControlName]="control.key" 
      [nzButtonStyle]="control.radioButtonStyle || 'solid'"
      [nzSize]="control.data.size"
      (ngModelChange)="onModelChange($event)"
      >
      <label 
        nz-radio-button 
        [nzValue]="opt[control.optionsFieldValue]" 
        *ngFor="let opt of (control.optionsList || control.options())" >
          {{ opt[control.optionsFieldLabel] | actrans }}
      </label>
      <label  nz-radio-button   *ngIf="control.optionsAllowEmpty"   nzValue=""   >
        {{ control.optionsEmptyLabel | actrans }}
      </label>
    </nz-radio-group>

    <nz-radio-group 
      *ngIf="control.controlType == 'radio'"   
      [id]="control.key" 
      [formControlName]="control.key" 
      (ngModelChange)="onModelChange($event)"
      class="radio-vertical"
      >
      <label 
        nz-radio 
        [nzValue]="opt[control.optionsFieldValue]" 
        *ngFor="let opt of (control.optionsList || control.options())" >
          {{ opt[control.optionsFieldLabel] | actrans }}
      </label>
    </nz-radio-group>


    <nz-row
      *ngIf="control.controlType == 'slider'"  
      nzGutter="8"
    >
      <nz-col [nzSpan]="control.data.sliderSpan || 18" >
        <nz-slider 
          [id]="control.key" 
          [formControlName]="control.key" 
          [nzMin]="control.data.min || 1" 
          [nzMax]="control.data.max || 100"
          [nzStep]="control.data.step || 1"  
          [required]="control.required"  
          (ngModelChange)="onModelChange($event)"
          
        ></nz-slider>
      </nz-col>
      <div nz-col [nzSpan]="24 - (control.data.sliderSpan || 18)">
        <!-- <nz-input-group 
          [nzSuffix]="control.data.suffix" 
          [nzPrefix]="control.data.prefix"> -->
          
          <nz-input-number
        
            [nzMin]="control.data.min || 1"
            [nzMax]="control.data.max || 100"
            [nzStep]="control.data.step || 1"  
            [required]="control.required"  
            [nzFormatter]="numberFormatter"
            [nzParser]="numberParser"
            [(ngModel)]="control.data.sliderInput" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onSliderInputChange($event)"
          ></nz-input-number>
        <!-- </nz-input-group> -->
      </div>
    </nz-row>

    <nz-row
      *ngIf="control.controlType == 'slider-range'"  
      nzGutter="8"
    >
    <div nz-col nzSpan="6">
      <nz-input-number
    
        [nzMin]="control.data.min || 1"
        [nzMax]="control.data.max || 100"
        [nzStep]="control.data.step || 1"  
        [required]="control.required"  
        [nzFormatter]="numberFormatter"
        [nzParser]="numberParser"
        
        [(ngModel)]="control.data.sliderInputMin" [ngModelOptions]="{standalone: true}"
        (ngModelChange)="onSliderRangeInputChange(0, $event)"
      ></nz-input-number>

    </div>
      <nz-col nzSpan="12" >
        <nz-slider 
          nzRange
          [id]="control.key" 
          [formControlName]="control.key" 
          [nzMin]="control.data.min || 1" 
          [nzMax]="control.data.max || 100"
          [nzStep]="control.data.step || 1"  
          [required]="control.required"  
          (ngModelChange)="onModelChange($event)"
          
        ></nz-slider>
      </nz-col>
      <div nz-col nzSpan="6">
        <nz-input-number
      
          [nzMin]="control.data.min || 1"
          [nzMax]="control.data.max || 100"
          [nzStep]="control.data.step || 1"  
          [required]="control.required"  
          [nzFormatter]="numberFormatter"
          [nzParser]="numberParser"
          
          [(ngModel)]="control.data.sliderInputMax" [ngModelOptions]="{standalone: true}"
          (ngModelChange)="onSliderRangeInputChange(1, $event)"
        ></nz-input-number>
  
      </div>
    </nz-row>


    <nz-input-group 
    *ngIf="control.controlType == 'mbsc-datepicker'" 
      nzPrefixIcon="calendar"
      [nzSuffixIcon]="control.data.suffix"
      
      >
      <input  
        
        class="ant-input"
        [id]="control.key" 
        [formControlName]="control.key" 
        
        mbsc-datepicker
        [controls]="['calendar']"
        [dateFormat]="control.data.dateFormat || 'YYYY-MM-DD'"

        select="date"
        display="anchored"
  
        
      [invalid]="mbscDatepickerInvalid"
        
      (onPageLoading)="mbscDatepickerOnPageLoading($event)"
      (onChange)="mbscDatepickerOnChange($event)"

      />

    </nz-input-group>

    <nz-input-group 
    *ngIf="control.controlType == 'date-picker'"   
    [nzSuffix]="control.data.suffix"
     [nzPrefix]="control.data.prefix"
     >

      <nz-date-picker 
      
        [id]="control.key" 
        [formControlName]="control.key" 
        (ngModelChange)="onModelChange($event)"
        [nzFormat]="control.data.format || 'yyyy-MM-dd'"
        [nzInputReadOnly]="control.data.readOnly !== undefined ? control.data.readOnly : bas.ui.r.xs"
        [nzInline]="control.data.inline"
        [nzPlaceHolder]="control.placeholder | actrans | str"
      ></nz-date-picker>

     
    </nz-input-group>

    <!-- <ng-template #prefixTemplateCommon><span nz-icon [nzType]="control.data.prefix"></span></ng-template>
    <ng-template #suffixTemplateCommon><span nz-icon [nzType]="control.data.suffix"></span></ng-template> -->

    <nz-date-picker 
      *ngIf="control.controlType == 'datetime-picker'"   
      [id]="control.key" 
      [formControlName]="control.key" 
      (ngModelChange)="onModelChange($event)"
      [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: control.data.minuteStep || 5 }"
      [nzFormat]="control.data.format || 'yyyy-MM-dd HH:mm'"
      [nzInputReadOnly]="control.data.readOnly !== undefined ? control.data.readOnly : bas.ui.r.xs"
      [nzInline]="control.data.inline"
      [nzAllowClear]="!!control.data.allowClear"
    ></nz-date-picker>

    <nz-range-picker
      *ngIf="control.controlType == 'date-range-picker'"   
      [id]="control.key" 
      [formControlName]="control.key" 
      (ngModelChange)="onChange($event)"
      [nzFormat]="control.data.format || 'yyyy-MM-dd'"
      [nzInputReadOnly]="control.data.readOnly"
      [nzInline]="control.data.inline"
      [nzDisabledDate]="pickerDisabledDate"
    ></nz-range-picker>

    <nz-range-picker
      *ngIf="control.controlType == 'datetime-range-picker'"  
      [id]="control.key" 
      [formControlName]="control.key" 
      [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: control.data.minuteStep || 5 }"
      [nzFormat]="control.data.format || 'yyyy-MM-dd HH:mm'"
      [nzInputReadOnly]="control.data.readOnly"
      [nzInline]="control.data.inline"

      (ngModelChange)="onChange($event)"
   
    ></nz-range-picker>
    <!--
      ngModel
      (nzOnCalendarChange)="onCalendarChange($event)"
      (nzOnOk)="onOk($event)"
    -->
    
    <nz-time-picker 
      *ngIf="control.controlType == 'time-picker'"   
      [id]="control.key" 
      [formControlName]="control.key" 
      (ngModelChange)="onModelChange($event)"
      [nzFormat]="control.data.format || 'HH:mm'"
      [nzMinuteStep]="control.data.minuteStep || 5"
    ></nz-time-picker>

    <div *ngIf="control.controlType == 'tags'">

      <nz-tag 
       class="tags-all"
       nzMode="checkable" 
       *ngIf="control.data.selectAll"   
       [nzChecked]="tagsIsAllSelected()" 
       (nzCheckedChange)="tagsChange($event, -1)">{{ 'common.all' | actrans }}</nz-tag>
       <span *ngIf="control.data.selectAll"    style="margin-right: 8px;">|</span> 
      <nz-tag 
        *ngFor="let opt of (control.optionsList || control.options())"
        nzMode="checkable" 
        [nzChecked]="tagsIsSelected(opt)" 
        (nzCheckedChange)="tagsChange($event, opt)">{{ opt[control.optionsFieldLabel] | actrans }}</nz-tag>
    </div>


    <div *ngIf="control.controlType == 'weekdays'">
      <!-- nzColor="#cd201f" -->
      <nz-tag 
        class="weekdays-all"
        nzMode="checkable" 
        [nzColor]="control.disabled ? (isAllWeekdays(control.fc?.value) ? '#FF0000' : '#FF7777') : undefined"
        [class.cursor-not-allowed]="control.disabled"
        [nzChecked]="isAllWeekdays(control.fc?.value)" 
        (nzCheckedChange)="weekdayCheckChange($event, -1)"
        [style.margin-right]="control.data.textLength == 'min' ? '2px' : '8px'"
        *ngIf="control.data.textLength != 'min' || true"
        >
          {{ 'common.all' | actrans }}
      </nz-tag>
      <span  *ngIf="control.data.textLength != 'min'" [style.margin-right]="control.data.textLength == 'min' ? '2px' : '8px'">|</span>  <!-- style="margin-right: 8px;"  -->
      <nz-tag 
       *ngFor="let wd of [2,3,4,5,6,7,1]"
        nzMode="checkable" 
        
        [nzColor]="control.disabled ? (control.fc?.value[wd] ? '#FF0000' : '#FF7777') : undefined"
        [class.cursor-not-allowed]="control.disabled"
        [nzChecked]="control.fc?.value[wd]" 
        (nzCheckedChange)="weekdayCheckChange($event, wd)"
        [style.margin-right]="control.data.textLength == 'min' ? '2px' : '8px'"
        >
          {{ (control.data.textLength == 'min' ? ('common.weekday.'+wd+'.min') : ('common.weekday.' + wd)) | actrans }}
        </nz-tag>
    </div>

    <div *ngIf="control.controlType == 'order-status'">
      <!-- nzColor="#cd201f" -->
      <nz-tag 
      class="status-all"
       nzMode="checkable" 
       [nzChecked]="isAllBoolMap(control.fc?.value)" 
       (nzCheckedChange)="boolMapCheckChange($event, -1)">{{ 'common.all' | actrans }}</nz-tag>
       <span style="margin-right: 8px;">|</span> 
      <nz-tag 
       *ngFor="let status of [0,1,2,3,4,5,6]"
        nzMode="checkable" 
        [nzChecked]="control.fc?.value[status]" 
        (nzCheckedChange)="boolMapCheckChange($event, status)">{{ control.data.statusList[status].mk | actrans }}</nz-tag>
    </div>

    <div *ngIf="control.controlType == 'switch'">
      <nz-switch
        [id]="control.key" 
        [formControlName]="control.key" 
        [nzDisabled]="control.disabled"
        [required]="control.required"  
      ></nz-switch>

      <!-- <label>
        <span>{{ control.mk ? (control.mk | actrans) : control.label }}</span>
      </label> -->
    </div>
    <div *ngIf="control.controlType == 'upload'">
      <h3>
        {{ control.mk ? (control.mk | actrans) : control.label }}
        <span *ngIf="options.showRequiredStar && control.required">&nbsp;*</span>
      </h3> 
      <!-- <p>Du kan laste opp bilde-filer og PDF-filer. </p> --><!--TODO: text-->

      <nz-upload
        nzType="drag"
        [nzMultiple]="true"
        [nzAction]="uploadUrl()"
        [nzHeaders]="uploadHeaders"
        [(nzFileList)]="control.data.upload.fileList"
        [nzFileType]="control.data.upload?.fileType || 'image/png,image/jpeg,application/pdf'"
        nzListType="text"
        [nzShowUploadList]="{ showRemoveIcon: true }"
        (nzChange)="handleUploadFile($event, control)"

        >
        <p class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">
          {{ 'app.cbapi5.page.admin.product.clickOrDragFilesHere' | actrans }}
          
        </p>
        <p class="ant-upload-hint">
          {{ 'app.cbapi5.page.admin.product.uploadFilesHint' | actrans }}
          <span *ngIf="control.data.upload.fileTypeDisplay" class="fgc-red"><br/>{{ control.data.upload.fileTypeDisplay }}</span>
        </p>
      </nz-upload>
      <div nz-typography nzType="danger" *ngIf="control.required && !control.data.upload?.fileList?.length">
        {{ 'app.lib.error.form.upload.required' | actrans }}
      </div>
    </div>
  
    <label
    *ngIf="control.controlType == 'checkbox'" 
     nz-checkbox 
     [id]="control.key" 
     [formControlName]="control.key" 
     [nzDisabled]="control.disabled"
     [required]="control.required"  


     (ngModelChange)="onModelChange($event)"
     >
     <!--       [ngModel]="control.value"    [ngModelOptions]="{standalone: true}", fungerer bare sammen med dobbel-binding:  [(ngModel)] -->
      <span *ngIf="!options.hideLabel">{{ control.mk ? (control.mk | actrans) : control.label }}</span>
      <span *ngIf="options.showRequiredStar && control.required"> *</span>

      <i 
      class="mkInfoIcon"
      *ngIf="control.mkInfo"
      nz-icon [nzType]="'info-circle'" 
      nz-tooltip 
      [nzTooltipTitle]="control.mkInfo | actrans | str" ></i>
    </label>

    <label
      *ngIf="control.controlType == 'label'" 

      >
      <!--       [class.text-right]="control.data.alignRight"  -->
        <span class="text-bold " [innerHTML]="control.data.html ? control.value : control.value">
          <!-- {{ control.value }} -->
        </span>
        <i 
        class="mkInfoIcon"
        *ngIf="control.mkInfo"
        nz-icon [nzType]="'info-circle'" 
        nz-tooltip 
        [nzTooltipTitle]="control.mkInfo | actrans | str" ></i>
    </label>

 
  </nz-form-control>
  

  
</nz-form-item>
<!-- <div class="errorMessage" *ngIf="!isValid">
  {{ control.mk ? (control.mk | actrans) : control.label }} er påkrevd
</div> -->
