import { inject } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';

import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MiscUtil } from '../util/misc.util';

export const EventName = {
  'AddToCart': { name: 'AddToCart',  gtm: 'add_to_cart' },        // GTM: currency, value, items: [{item_id: ""; item_name: "" }]
                                                              // PIX: currency, value , content_ids: ['ABC123', 'XYZ789'], content_name : "sdfsdf", ,    (contents: [{'id': 'ABC123', 'quantity': 2} ])
  'PageView': { name: 'PageView',  gtm: 'page_view'},
  'Purchase': { name: 'Purchase',  gtm: 'purchase', cb: "PlaceOrder" },             // GTM: currency, value, items: [{item_id: ""; item_name: "" }], transaction_id: ""
  'ViewContent': { name: 'ViewContent',  gtm: 'view_item' }         // GTM: currency, value, items: [{item_id: ""; item_name: "" }]
}

// https://support.google.com/analytics/answer/9267735?hl=no&ref_topic=13367566&sjid=13662719434059911432-EU
// https://developers.facebook.com/docs/meta-pixel/reference#standard-events
/*
gtag("event", "add_to_cart", {
  currency: "USD",
  value: 7.77,
  items: [
    {
      item_id: "SKU_12345",
      item_name: "Stan and Friends Tee",
      affiliation: "Google Merchandise Store",
      coupon: "SUMMER_FUN",
      discount: 2.22,
      index: 0,
      item_brand: "Google",
      item_category: "Apparel",
      item_category2: "Adult",
      item_category3: "Shirts",
      item_category4: "Crew",
      item_category5: "Short sleeve",
      item_list_id: "related_products",
      item_list_name: "Related Products",
      item_variant: "green",
      location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      price: 9.99,
      quantity: 1
    }
  ]
});
*/

// 'InitiateCheckout' |
// 'AddPaymentInfo' |

// 'AddToWishlist' |
// 'CompleteRegistration' |
// 'Contact' |
// 'CustomizeProduct' |
// 'Donate' |
// 'FindLocation' |
// 'Lead' |
// 'Schedule' |
// 'Search' |
// 'StartTrial' |
// 'SubmitApplication' |
// 'Subscribe';


declare const fbq: any;

export class TrackingService {


  private doc: Document;
  private renderer: Renderer2
  private platformId: Object
  private router: Router;

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    // documentRef(): any {
    //   return document;
    // },
  };
  

  private config:any = {
    cb: {
      enabled: false,
    },
    pixel: {
      enabled: false,
      id: "",
      
    },
    gtm: {
      enabled: false,
      id: "",
    }

   }
   /*
test; {
  
    pixel: {
      enabled: true,
      id: "1392750311341973", // app.test.cloudbooking.io
      
    },
    gtm: {
      enabled: true,
      id: "GTM-NQL4QB4F",  // app.test.cloudbooking.io
      // GTM-M543MJMQ - test.cloudbooking.io
    }
}


   */

  private consent:any = {
    "google-analytics": false,
    "marketing": true,
  }


  constructor(public bas: BooktechAppService) {
    if (this.bas.envtest) console.log("TrackingService.constructor");

    let rendererFactory: RendererFactory2 = inject(RendererFactory2);

    this.platformId = inject(PLATFORM_ID);
    this.doc = inject(DOCUMENT);
    this.renderer = rendererFactory.createRenderer(null, null);
    this.router = inject(Router);

    if (this.bas.settings.appId == "cbsite") {
      this.consent = {
        "google-analytics": false,
        "marketing": false,
      }
    }


    
    this.bas.es.on("KlaroConsentUpdate", (event:any) => {
      // if (this.bas.envtest) console.log("TrackingService.KlaroConsentUpdate, event: ", event);
      this.consent[event.service.name] = event.consent
    })
   
  }

  //

  init(config: object) {
    let c = this.config = MiscUtil.extend(this.config, config);

    if (c.pixel.enabled) this.initPixel();
    if (c.gtm.enabled) this.initGtm();

    if (this.router) {
      // Log page views after router navigation ends
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
        // if (this.bas.envtest) console.log("TrackingService.NavigationEnd, event: ", event);

        this.track(EventName.PageView.name);

      });
    }

    //this.track(EventName.PageView.name);

  }
  
  /*

this.bas.ts.track("AddToCart", {
  content_name: m.cName,
  content_id: m.ptId,
  currency: "NOK",
  value: this.cart.sum 
});

this.bas.ts.track("Purchase", {
  content_name: m.cName,
  content_id: m.ptId,
  currency: "NOK",
  value: this.booking.amountDouble 
});

  */
  track(eventName:string, args?:any) {
   
    // export const EventNames = {
    //   'AddToCart': { gtm: 'add_to_cart' },        // GTM: currency, value, items: [{item_id: ""; item_name: "" }]
    //                                               // PIX: currency, value , content_ids: ['ABC123', 'XYZ789'], content_name : "sdfsdf", ,    (contents: [{'id': 'ABC123', 'quantity': 2} ])
    //   'PageView': { gtm: 'page_view'},
    //   'Purchase': { gtm: 'purchase'},             // GTM: currency, value, items: [{item_id: ""; item_name: "" }], transaction_id: ""
    //   'ViewContent': { gtm: 'view_item' }         // GTM: currency, value, items: [{item_id: ""; item_name: "" }]
    // }

    try {


      let gtmArgs:any = { };
      let pixArgs:any = { };
      let cbArgs:any = { };

      let ens:any = EventName;
  
      let gtmEventName = ens[eventName]?.gtm || eventName;
      let cbEventName = ens[eventName]?.cb || eventName;
  
      if (args) {
        for (let key of ["currency", "value", "content_id", "content_name" ]) {
          if (args[key]) {
            pixArgs[key] = args[key];
            gtmArgs[key] = args[key];
          }
        }
  
        if (args.content_id && args.content_name) {
          pixArgs["content_ids"] = [ args.content_id ];
          // pixArgs["content_name"] = args.content_name;
  
          gtmArgs["items"] = [
            { 
              id: args.content_id || "", 
              // itename: args.content_name || ""  
            }
          ];
  
        }
  
        if (args.transaction_id) {
          gtmArgs["transaction_id"] = args.transaction_id;
        }
      }
  
      if (this.bas.envtest) console.log("TrackingService.track, event: " + eventName + ", args: ", args , ", gtmArgs: ", gtmArgs, ", pixArgs: ", pixArgs, ", config: ", this.config);
  
      if (this.isPixelLoaded()) {
  
        this.trackPixel(eventName, pixArgs);
      } 
      // else if(this.bas.envtest) console.log(MiscUtil.getLogText("TrackingService.track.isPixelLoaded == false"));
  
      if (this.isGtmLoaded()) {
  
        this.trackGtm(gtmEventName, gtmArgs);
      } 
      // else if(this.bas.envtest) console.log(MiscUtil.getLogText("TrackingService.track.isGtmLoaded == false"));
  
      
      if (this.config.cb.enabled) {
  
        this.trackCb(cbEventName, args);
      } 
  

    } catch (err) {
      console.log("track.err: ", err);
    }

  }


  // cb
  trackCb(eventName:string, args?:any) {
    // https://test.cloud-booking.net/servlet/json/nesbyen/_?aType=analytics&action=save&type=WebPage&actionAsString=PageView&key=company/index&label=NesbyenBooking.com&value=URL&ai_url=URL
  
    let l =  this.bas.LOC;
    let lp = l.path() || "/";

    let basePath = lp.indexOf("?") >= 0 ? lp.substring(0, lp.indexOf("?")) : lp; 
    let qps = lp.indexOf("?") >= 0 ? lp.substring(lp.indexOf("?") + 1) : ""; 


    let key = "path-" + basePath;
    let type = "WebPage";

    if (this.bas.settings.appId == "cbsite") {
      if (basePath.indexOf("/p/") >= 0) {

        let pid =  basePath.indexOf("-") >= 0 ? parseInt(basePath.substring(basePath.lastIndexOf("-") + 1)) : "";
        // if (this.bas.envtest) console.log("TrackingService.trackCb, pid: " + pid);

        if (pid) {
          key = "product-" + pid;
          type = "Product";
        }

        // let pageName =  basePath.indexOf("/") >= 0 ? basePath.substring(basePath.lastIndexOf("/") + 1) : "";
        // 
        // if (pageName) {
        //   // /booking/kajakkpadling/no/p/Gavekort/Valgfritt beløp Vestfold og Telemark/Gavekort-1865
        //   basePath = basePath.substring(0, basePath.indexOf("/p/") + 3);

        //   basePath += pageName;
        //   if (this.bas.envtest) console.log("TrackingService.trackCb, basePath: " + basePath);

        // }



      } 
    }
   


    let value = lp;
    // if (value.indexOf("?") >= 0) value = value.substring(0, value.indexOf("?")); 

    let url = lp;
    if (this.bas.nossr) url = window.location.href;

    let label = this.bas.ui.getTitle() || "";

    let consentMarketing = this.consent.marketing;

    let params = {
      aType: "analytics",
      action: "save",
      type: type,
      actionAsString: eventName,
      key: key,
      label: label,
      value: value,
      ai_url: url,
      consentMarketing: consentMarketing
    }
    
    if (this.bas.envtest) console.log("TrackingService.trackCb, event: " + eventName + ", .path: " + lp + ", params: ", params);

    this.bas.ws.json(params, { checkSuccess:false }).then((json) => {
      // if (this.bas.envtest) console.log("TrackingService.trackCb.then: ", json);

    });

    /*
host: "192.168.1.60:8200"
hostname: "192.168.1.60"​
href: "https://192.168.1.60:8200/api/nesbyen/widget/test"

origin: "https://192.168.1.60:8200"
pathname: "/api/nesbyen/widget/test"
    */
    /*
       type: "WebPage",
        actionAsString: "PageView",
        key: "product-" + $cb.obj.product.id,
        label: $cb.obj.product.mkName,
        value: $(this).attr("href"),
        
        ai_url: window.location.href
    */
  }

  // Google Tag Manager
  initGtm(gtmId = this.config.gtm.id): void {

    if (!gtmId) {
      console.log("initGtm, gtmId == undefined");
      return;
    }

    if (this.config.klaro) {


      let gtmScript = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;

      // if(this.bas.envtest) console.log("initGtm, gtmScript: " + gtmScript);


      
      const scriptElement = this.renderer.createElement('script');
      // this.renderer.setAttribute(scriptElement, 'async', 'true');
      this.renderer.setAttribute(scriptElement, 'id', 'gtm-script');
      this.renderer.setAttribute(scriptElement, 'type', 'text/plain');
      this.renderer.setAttribute(scriptElement, 'data-name', 'google-tag-manager');
      this.renderer.setAttribute(scriptElement, 'data-type', 'application/javascript');
      this.renderer.setProperty(scriptElement, 'innerHTML', gtmScript);
      this.renderer.appendChild(this.doc.head, scriptElement);

  


    } else {
      this.pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
  
      let src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId
  
      this.bas.script.loadScript({
        id: "gtm-script",
        name: "gtm",
        src: src
      }).then((res) => {
        if(this.bas.envtest) console.log(MiscUtil.getLogText("TrackingService.initGtm, res: "), res);
  
        //this.pushOnDataLayer({ event: 'page' })
  
        // if(this.bas.envtest) console.log(MiscUtil.getLogText("TrackingService.initGtm, dataLayer:"), this.getDataLayer());
  
      });
    }



  }

  private getDataLayer(): any[] {

    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
    if(this.bas.envtest) console.log(MiscUtil.getLogText("TrackingService.pushOnDataLayer: ") + JSON.stringify( dataLayer ));

  }

  public trackGtm(eventNname:any, args?:any) {

    let obj:any = { 
      event: eventNname
    }
    if (args) MiscUtil.extend(obj, args);
    this.pushOnDataLayer(obj);

  }


  isGtmLoaded() {
    if (isPlatformBrowser(this.platformId)) {
      const scriptElement = this.doc.getElementById('gtm-script');
      return !!scriptElement;
    }
    return false;
  }



  // Meta Pixel


  /**
   * Initialize the Pixel tracking script
   * - Adds the script to page's head
   * - Tracks first page view
   */
  initPixel(pixelId = this.config.pixel.id): void {
    if (this.isPixelLoaded()) {
      console.warn('Tried to initialize a Pixel instance while another is already active. Please call `remove()` before initializing a new instance.');
      return;
    }
    this.config.pixel.enabled = true;
    this.addPixelScript(pixelId);
  }

  /** Remove the Pixel tracking script */
  removePixel(): void {
    this.removePixelScript();
    this.config.pixel.enabled = false;
  }

  /**
   * Track a Standard Event as predefined by Facebook
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/reference Facebook Pixel docs - reference}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   * 
   * 
   *  fbq('track', 'Purchase', {currency: "USD", value: 30.00});
   * 	fbq('track', 'AddToCart');


ViewContent

fbq('track', 'AddToCart', {
  content_ids: ['1234','1853','9386'],
  content_type: 'product',
  currency: "NOK", 
  value: 30.00
});

   */
  trackPixel(
    eventName: string, //PixelEventName, 
    properties?: any
  ): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.isPixelLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.');
      return;
    }

    if (properties) {
      fbq('track', eventName, properties);
    } else {
      fbq('track', eventName);
    }


  }

  /**
   * Track a custom Event
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-conversions Facebook Pixel docs - custom conversions}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
  trackPixelCustom(eventName: string, properties?: object): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.isPixelLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.');
      return;
    }

    if (properties) {
      fbq('trackCustom', eventName, properties);
    } else {
      fbq('trackCustom', eventName);
    }
  }

  /**
   * Adds the Facebook Pixel tracking script to the application
   * @param pixelId The Facebook Pixel ID to use
   */
  private addPixelScript(pixelId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const pixelCode = `
    var pixelCode = function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');`;


    const scriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElement, 'async', 'true');
    this.renderer.setAttribute(scriptElement, 'id', 'pixel-script');
    this.renderer.setAttribute(scriptElement, 'type', 'text/javascript');
    this.renderer.setProperty(scriptElement, 'innerHTML', pixelCode);
    this.renderer.appendChild(this.doc.head, scriptElement);
  }

  /** Remove Facebook Pixel tracking script from the application */
  private removePixelScript(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const pixelElement = this.doc.getElementById('pixel-script');
    if (pixelElement) {
      pixelElement.remove();
    }
  }

  /** Checks if the script element is present */
  private isPixelLoaded(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const pixelElement = this.doc.getElementById('pixel-script');
      return !!pixelElement;
    }
    return false;
  }



}

/*


<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '661472214813161');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=661472214813161&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


  $('#addToCartButton').click(function() {
    fbq('track', 'Purchase', {currency: "USD", value: 30.00});
  });



  Website action
  Description

Add payment info
The addition of customer payment information during a checkout process, such as clicking on a button to save billing information

Add to cart
The addition of an item to a shopping cart, such as clicking an Add to Cart button on a website

Add to wishlist
The addition of items to a wishlist, such as clicking an Add to Wishlist button on a website

Complete registration
A customer’s submission of information in exchange for a service provided by a business, such as signing up for an email subscription

Contact
A phone, SMS, email, chat or other type of contact between a customer and a business

Customize product
The customization of products through a configuration tool or other application a business owns

Donate
The donation of funds to an organization or cause

Find location
The discovery of a store location online, with the intention to visit, such as searching for a product and finding it at a local store

Initiate checkout
The start of the checkout process, such as clicking a Checkout button

Lead
The submission of information by customers who understand they may be contacted at a later date by your business. such as, submitting a form or signing up for a trial

Purchase
The completion of a purchase, usually signified by receiving order or purchase confirmation or a transaction receipt, such as landing on a thank you or confirmation page

Schedule
The booking of an appointment to visit a location

Search
A search performed on a website, app or other property, such as a product or travel search

Start trial
The start of a free trial of a product or service, such as a trial subscription

Submit application
The submission of an application for a product, service or program you offer, such as a credit card, educational program or job

Subscribe
The start of a paid subscription for a product or service you offer

View content
A visit to a web page, such as a product or landing page. View content indicates whether someone visits a web page, but not what they do or see on that web page.

  https://www.facebookblueprint.com/student/activity/212738-connect-your-data-with-pixel-and-conversions-api?fbclid=IwAR1VBTualc7taJYi9JctqNQNlDG8WZWA-sttFnZNpjxt7oRl9_rk7xO-H-8#/page/5fc6e67d4a46d349e9dff7fa


*/

