import { inject } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';
import { MiscUtil } from '../util/misc.util';
import { PriceRule } from '../util/const';
import { FilterPipe } from '../pipes/ngx-filter.pipe';

import { DATAID } from './data.service';

export class OrderService {

  filter = new FilterPipe();
  constructor(public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log("OrderService.constructor");

   
  }

  
  placeOrder(cart:any, extraParams:any, jsonData?:any):Promise<any> {
    
    var user = this.bas.ds.login.user;
    var params:any = { 
      actionType: "appService", 
      action: "placeOrder", 
      acceptEula: true
    };

    if (user) params.userId = user.id;

    MiscUtil.extend(params, extraParams);

    if (jsonData) params.jsonData = JSON.stringify(jsonData);


    MiscUtil.extend(params, MiscUtil.getCartParams(cart)); 

    if (this.bas.envtest) console.log("OrderService.placeOrder: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.placeOrder.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }


  easyPay(id:number, payType:string, extraParams:any = { }):Promise<any> {
    var params:any = { 
      actionType: "order", 
      action: "easyPay", 
      id: id,
      payType: payType,
      url: this.bas.nossr ? location.href : ""
    };

    MiscUtil.extend(params, extraParams);

    if (this.bas.envtest) console.log("OrderService.easyPay: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json) => {
      if (this.bas.envtest) console.log("OrderService.easyPay.then, ", json);
      
      if (json.success) {

        

      }

      return json;
    });
  }



  updatePwAmount (pw:any) {
    let priceRule = pw.priceRule || "PriceCount";  // PriceCountDaysGuests

    let start = this.bas.ui.dateParseIso(pw.startAsIso8601);
    let end = this.bas.ui.dateParseIso(pw.endAsIso8601);
    
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount start, " + pw.startAsIso8601 + " -> " + start);
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount end, " + pw.endAsIso8601 + " -> " + end);


    //static getPrice (priceRuleId:number|string, amountDouble:number, quantity:number, days?:number, start?:Date, end?:Date, guests?:number)
    let amount = MiscUtil.getPrice(priceRule, pw.unitAmount, pw.quantity, 1, start, end, pw.guestCounts?.count); // Setter days til 1 slik at vi ikke regner ut antall dager
    // if (this.bas.envtest) console.log("OrderService.updatePwAmount.amount, " + amount);
    if (amount === undefined) {

      return;
    }
    pw.amountDouble = amount;

  }

  getCartItems(items:any[] = []) {
    // let items:any[] = cart?.items || [];
    return items.map((pw:any) => {
      let item:any = { 
        product: pw.productId,
        periodAsRange: pw.periodAsRange,
        guestCountsAsString: pw.guestCountsAsString,
        customAmountDouble: pw.customAmountDouble,
        jsonData: pw.jsonData || { }
        
      }

      if (pw.accessories) {
        item.jsonData.accessories = { };
        pw.accessories.filter((acc:any) => !acc.findMandatory && acc.quantity).map((acc:any) => {
          item.jsonData.accessories[acc.productId] = {
            productId: acc.productId,
            quantity: acc.quantity
          }
        });
      }


      return item;
    }); 

  }


  updateAmount(pw:any) {
    let amount = pw.amountDouble;
    for (let acc of pw.accessoriesOptional) {
      amount += acc.amountDouble;
    }

    if (pw.groupItems?.length) {
      for (let gi of pw.groupItems) {
        amount += gi.amountDouble;
      }
    }
    pw.calcAmount = amount;
  }
    
  async updatePw(pw:any, opts:any = { }) {

    let ui = this.bas.ui;
    // let pw = this.pw ;
    if(this.bas.envtest) console.log("OrderService.updatePw, pw.input: ", pw);

    var params:any = {
      aType: "appService",
      action: "getPwCart",
      product: pw.productId
    }

    if (opts?.params) {
      MiscUtil.extend(params, opts?.params)
    }


    if (pw.productObj) {
      let p = pw.productObj;
      if (pw.productObj.tcTime || (pw.productObj.tcDate && pw.productObj.singleDateProduct)) {
        if (pw.startDateAsString) params.startDateAsString = pw.startDateAsString;
        if (pw.productObj.tcTime && pw.timePeriodAsString) params.timePeriodAsString = pw.timePeriodAsString;
  
      } else if (p.periodProduct) {
        if (pw.periodAsRange) params.periodAsRange = pw.periodAsRange;
     
      }
      
      if (p.guestProduct && pw.guestCounts) params.guestCountsAsString = pw.guestCounts.guestCountsAsString;

      if (p.findIsPriceCustom) params.customAmountDouble = pw.customAmountDouble;

      if (pw.type) {
        params.type = pw.type;
        // if (pw.type == "PROVIDER") params.provider = true;
        // else    if (pw.type == "OVERVIEW") params.overview = true;
      } 


      if (pw.areaId)  params.area = pw.areaId || "";
     
    } else {

      if (pw.periodAsRange) params.periodAsRange = pw.periodAsRange;
      if (pw.guestCountsAsString) params.guestCountsAsString = pw.guestCountsAsString;
    }

   
    let res = await this.bas.ws.json(params, { });

    if(this.bas.envtest) console.log("OrderService.getPwCart: ", res);
    if (res.success) {
      // this.cart = res.cart;

      let pwPrev = pw;
      pw = res.cart.items[0];

      if (pw.availablePoolUnits) {
        pw.jsonData = pw.jsonData || { };
        let apu = pw.availablePoolUnits.find((i:any) => i.id == pwPrev.jsonData?.poolUnitId);
        pw.jsonData.poolUnitId = apu?.id || '';
      }

      let prod = pw.productObj;
      
      this.bas.acs.updateProductObj(prod);

      for (let childType of [ "accessories", "groupItems" ] ) {
        if (pwPrev[childType] === undefined) continue; 

        let childMap = MiscUtil.listToMap(pw[childType] || [], "productId"); 
        for (let childPrev of pwPrev[childType] || []) {
          let childNext = childMap[childPrev.productId];
          if (childNext && (childPrev.quantity != childNext?.quantity)) {
              
            if(this.bas.envtest) console.log("updating childNext, name: "+childNext.product+": " + childNext.quantity + " -> " + childPrev.quantity);
            childNext.quantity = childPrev.quantity;
            this.bas.os.updatePwAmount(childNext);
            
          }
        }
      }
 
  

      pw.startDateAsJsDate = this.bas.ui.stringToDate(pw.startDateAsString);
      pw.endDateAsJsDate = this.bas.ui.stringToDate(pw.endDateAsString);
      pw.periodArray =[  pw.startDateAsJsDate,  pw.endDateAsJsDate ]

      // if(this.bas.envtest) console.log("OrderService.getPwCart.qty: ", pw.quantity);
      
      for (let gcIdx in pw.guestCategories) {
        if (pw.guestCounts.map[gcIdx] === undefined) pw.guestCounts.map[gcIdx] = 0; 
      }

      
			let errorMsg = "";
			
      if (pw) {
       if (    !pw.isOrderable 
            && !prod.productConfig.isRoute
            && prod.availabilityInfo
            && prod.findTypeExperience 
            && pw.selectableDatesNext !== undefined 
            && pw.selectableDatesNext.length == 0
            
            ) {
              errorMsg = ui.actrans("web.servlet.json.updatePrice.soldOut"); 
              pw.isSoldOut = true;
            
		
				} else if (pw.isStartBeforeStartDateThresholdMin) errorMsg = ui.actrans("web.servlet.json.updatePrice.startDateThresholdMin", [pw.startDateThresholdMin]);
				else if (pw.isStartAfterStartDateThresholdMax) errorMsg = ui.actrans("web.servlet.json.updatePrice.startDateThresholdMax", [pw.startDateThresholdMax]);
				else if (pw.isGuestCountLessThenMin) errorMsg = ui.actrans("web.servlet.json.updatePrice.guestCountLessThenMin", [prod.findMinGuests]);
				else if (pw.isGuestCountMoreThenMax) errorMsg = ui.actrans("web.servlet.json.updatePrice.guestCountMoreThenMax", [prod.findMaxGuests]);
				else if (prod.timeProduct && !prod.hasTimes) errorMsg = ui.actrans("web.servlet.json.updatePrice.noTimeAvailable"); 
				else if ( !pw.isOrderable) {
          errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
                
        }
        else if (!pw.canBeOrdered) {
          errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
          
        }
//				else if (!pw.isOrderable) errorMsg = ui.actrans("web.servlet.json.updatePrice.notAvailable"); 
				
				
				if (errorMsg == "" && !prod.timeProduct) {
					
					if (prod.findTypeLodging && pw.guestCounts.count > pw.cap) errorMsg = ui.actrans("web.servlet.json.updatePrice.cap");
					else if (pw.isLessThenMinDaysBooking) errorMsg = ui.actrans("web.servlet.json.updatePrice.lessThenMinDaysBooking", [pw.minDaysBooking]);
          else if (pw.isMoreThenMaxDaysBooking) errorMsg = ui.actrans("web.servlet.json.updatePrice.moreThenMaxDaysBooking", [pw.maxDaysBooking]);
					else if (pw.totalAmount === "") errorMsg = ui.actrans("web.servlet.json.updatePrice.priceIsNull");
					
				}
			} else errorMsg = ui.actrans("web.servlet.json.updatePrice.priceIsNull");
			
			if (errorMsg == "" && pw.findMkError != "") errorMsg = pw.findMkError;
			
			if (errorMsg == "NULL") errorMsg = "";
			
			// if (errorMsg != "" && !p.availabilityInfo && aiContainer.length) errorMsg = "";
			
      pw.errorMsg = errorMsg;
			if (errorMsg != "") {

      }
 
      res.pw = pw;
      return res;
    }

    // Feilmelding
    res.pw = pw;
    return res;
  }

  //

  updateProducts():Promise<any> {

    return this.bas.ds.getFromJson(DATAID.ORDER_PRODUCTS, { actionType: "appService", action: "getProducts" }, true).then((json) => {
      if(this.bas.envtest) console.log("OrderService, updateProducts.then: ", json);
    });


  }

  getProducts(productType?:string):any[] {
    let products = this.bas.ds.getValue(DATAID.ORDER_PRODUCTS, { products: []}).products;
    if (!productType) return products; 

    return this.filter.transform(products, 
      { typeObj: { ptid: productType }}
    );

  }

  getProduct(id:number):any {
    let list:any[] = this.filter.transform(this.getProducts(), 
      { id: id }
    );
    return list.length ? list[0] : undefined;
  }

  getProductInfo(id:any):Promise<any> {
    return this.bas.ws.json({ actionType: "appService", action: "getProduct", id: id }).then((json) => {
     
      return json.product;
    });
  }



  updateUserOrders(coreData = false):Promise<any> {

    return this.bas.ds.getFromJson(DATAID.ORDER_ORDERS, { actionType: "appService", action: "getOrders", userOrders: true, coreData: coreData }, true).then((json) => {
      // if(this.bas.envtest) console.log("OrderService, updateUserOrders.then: ", json);

      // this.ws.ds.saveData
    });
  }

  getUserOrders(productType?:string, coreData = false):any[] {
    let orders = this.bas.ds.getValue(DATAID.ORDER_ORDERS, { orders: []}).orders || [];
    if (!productType) return orders; 

    // if(this.bas.envtest) console.log("OrderService.getOrders: " + orders.length + ", productType: " + productType);


    let transformObj = coreData
      ?  { product: { typeObj: { ptid: productType }} }
      :  { item0: { productObj: { typeObj: { ptid: productType }} } };


    let result = this.filter.transform(orders, transformObj );
    // if(this.bas.envtest) console.log("OrderService.getOrders: " + orders.length + "; result: " + result.length);
    return result;
  }

  getOrder(orderId:any, params:any = { }):Promise<any> {

    MiscUtil.extend(params, { actionType: "appService", action: "getOrder", id: orderId });
    return this.bas.ws.json(params).then((json) => {
     
      return json;
    });
  }


  changeOrder(biid:any, pw:any, extraParams:any, jsonData?:any, coreData = false):Promise<any> {
    
    // var user = this.ws.ds.get(DATAIDS.APP_LOGIN).user;
    var params:any = { 
      actionType: "appService", 
      action: "changeOrder", 
      // acceptEula: true,
      // userId: user.id
      biid: biid
    };

    MiscUtil.extend(params, extraParams);

    if (jsonData) params.jsonData = JSON.stringify(jsonData);


    MiscUtil.extend(params, {
      startAsIso8601: pw.startAsIso8601,
      endAsIso8601: pw.endAsIso8601
    }); 

    if(this.bas.envtest) console.log("OrderService.changeOrder: params: ", params);

    return this.bas.ws.json(params, { showLoading: true }).then((json:any) => {
      if(this.bas.envtest) console.log("OrderService.changeOrder.then, ", json);
      
      if (json.success) {
        return this.updateUserOrders(coreData);
      }
      return json;
    });
  }

  getPwCart(paramsInput:any, onSuccess?:any):Promise<any> {

    var params = { 
      actionType: "appService", 
      action: "getPwCart",
      // product: this.pw.productId,
      // startAsIso8601: this.pw.startAsIso8601, //this.ws.m(this.start).local().format(ISO8601woZ),
      // endAsIso8601: this.pw.endAsIso8601 //this.ws.m(this.end).local().format(ISO8601woZ)
    }
    MiscUtil.extend(params, paramsInput);

    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("OrderService.getPwCart.then, ");
      
      if (json.success) {
        
        let pw = json.cart.items[0];
        if (onSuccess) onSuccess(pw);
      }

    });

  }


  updateUser(paramsInput:any, onSuccess?:any):Promise<any> {
    var appLogin = this.bas.appLogin;
    
    var params:any = { 
      actionType: "app", 
      action: "updateUser",
    };
    for (let field in paramsInput) {
      params["user_" + field] = paramsInput[field];
      appLogin.user[field] = paramsInput[field];
    };

    // this.bas.ds.save(DATAID.APP_LOGIN, al); //TODO

    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("OrderService.updateUser.then");
      
      if (json.success) {
        if (onSuccess) onSuccess(json.user);
      }

      return json.user;

    });

  }


  checkIn(order:any, checkIn = true):Promise<any> {
    var appLogin = this.bas.appLogin;
    
    var params:any = { 
      actionType: "appService", 
      action: checkIn ? "checkIn" : "checkOut",
      id: order.id
    };

    return this.bas.ws.json(params).then((json) => {
      if(this.bas.envtest) console.log("OrderService.checkIn.then");
      
      if (json.success) {
        this.updateUserOrders();
      }

      return json.booking;
    });

  }



}
