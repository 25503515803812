import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { DynformControl } from '../../../model/dymform-control';
import { BtFormRowComponent } from '../../form/bt-form-row/bt-form-row.component';

import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';



export interface DynamicModalData {
  desc?: string;
  controls: DynformControl[];
  obj?:any;
}

@Component({
  selector: 'bt-dynamic-modal',
  standalone: true,
  imports: [ LibModulesWolcModule, BtFormRowComponent ],
  templateUrl: './bt-dynamic-modal.component.html',
  styleUrl: './bt-dynamic-modal.component.scss'
})
export class BtDynamicModalComponent {
  bas = inject(BooktechAppService);

  readonly #modal = inject(NzModalRef);
  readonly dmd: DynamicModalData = inject(NZ_MODAL_DATA);

  edit:any = {
    
  }

  // destroyModal(): void {
  //   this.#modal.destroy({ data: 'this the result data' });
  // }

  constructor() {

  }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    if (this.bas.envtest) console.log("BtDynamicModalComponent.setupForm: ", this.dmd);
      
    if (this.dmd.controls?.length) {
      this.bas.fs.updateFormObj(this.edit, this.dmd.controls, this.dmd.obj || { });
    }

  }
  
  cancel() {

    this.#modal.destroy({ event: "cancel" });
  }

  submit() {

    if (this.edit.obj) {
      let rv = this.bas.fs.rv(this.edit);
      if (this.bas.envtest) console.log("BtDynamicModalComponent.submit: ", rv);
      if (rv === false) return;
  
      this.#modal.destroy({ event: "submit", data: rv });
      return;
    }
    this.#modal.destroy({ event: "submit" });


  }

}


