import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Injectable, Inject, Injector } from '@angular/core';

import { DynformControl } from '../../../model/dymform-control';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
//  

import { ContentChange } from 'ngx-quill'
import { debounceTime } from 'rxjs/operators'

import {BehaviorSubject, Observable} from 'rxjs';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

import { MiscUtil } from '../../../util/misc.util';

// import { autocomplete } from '../../../services/ui.service';


@Component({
  selector: 'bt-dynamic-form-control',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-dynamic-form-control.component.html',
  styleUrls: ['./bt-dynamic-form-control.component.scss'],
})
export class BtDynamicFormControlComponent implements OnInit {
  bas = inject(BooktechAppService);

  @Input() control!: DynformControl;
  @Input() form!: UntypedFormGroup;
  @Input() options:any = { };

  @Output() change = new EventEmitter<any>();

  data:any = {

  }

  // @ViewChild('quillEditor', {
  //   static: true
  // }) quillEditor: QuillEditorComponent|null = null;

  Infinity = Infinity;

  quillModules:any = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],

      ['bold', 'italic', 'underline'], // , 'strike'       // toggled buttons
      // ['blockquote', 'code-block'],
  
      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction
  
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      // [{ 'align': [] }],
  
      // [ 'emoji' ], // TODO: https://github.com/KillerCodeMonkey/ngx-quill-example/blob/master/src/app/emoji/emoji.component.ts
      ['link' ],
      ['clean'],                                         // remove formatting button
  
      // ['link', 'image', 'video']                         // link and image, video
        //TODO: https://github.com/benwinding/quill-html-edit-button
    ]
  };

  // upload:any = {
  //   fileList: [],
  // }
  
  autocompleteTerm = new BehaviorSubject<string>('');

  constructor() {

  }

  ngOnInit() {
    // console.log("control: ", this.control);

    // if (this.control.controlType == "select") {
    //   if(this.bas.envtest) console.log("control: ", this.control, "; options: ", this.control.options());
    // } 

    if (this.control) {
      if (this.control.controlType == "slider") {
        this.control.data.sliderInput = this.control.value;
      } else  if (this.control.controlType == "upload") {
        this.control.data.upload = this.control.data.upload || { };
        this.control.data.upload.fileList = this.control.data.upload.fileList || [ ];
        
      } else  if (this.control.controlType == "input-autocomplete") {
        this.control.data.autocomplete = this.control.data.autocomplete || { };
        let ac = this.control.data.autocomplete;
        // if (!this.control.data.autocomplete.options ) 
        ac.optionsAll = ac.options || this.control.optionsList || this.control.options() || [];
        ac.options = ac.optionsAll;

        if (ac.type) {

          this.autocompleteTerm.pipe(
            debounceTime(1000)
          ).subscribe(async (term) => {
            ac.term = term;
            // if(this.bas.envtest) console.log("autocomplete.pipe.1000, ac: ", ac);
            if (term == "") {
              ac.options = [];
              return; 
            }

            let json = await this.bas.ws.json({
              aType: "autocomplete",
              action: "autocomplete",
              type: ac.type,
              term: term
            })
            // if(this.bas.envtest) console.log("autocomplete.pipe.1000, json: ", json);
            ac.options = [...json];

          });

          // ac.options2 = this.autocompleteTerm.pipe(
          //   autocomplete(1000, ((term:string) => {
              
          //   if(this.bas.envtest) console.log("autocomplete.pipe.1000, term: " + term);
          //     let json = this.bas.ws.json({
          //       aType: "autocomplete",
          //       action: "autocomplete",
          //       type: ac.type,
          //       term: term
          //     })
          //     if(this.bas.envtest) console.log("autocomplete.pipe.1000, json: ", json);
          //     // ac.options = json;
          //     return json;
          //   }))
          // )
        }

      } else  if (this.control.controlType == "textarea") {
        if (this.control.data.json) {
          // let val =  this.control.value;
          // this.control.data.height = val ? Math.min(val.split(/\r\n|\r|\n/).length * 20, 80) : 80
        }
      }

    }

  }

  get isValid() { 
    let control:AbstractControl = this.form.controls[this.control.key];

    return control.pristine ||  control.valid; 
  }


  onContentChanged(event:ContentChange) {
    // console.log(MiscUtil.getTimeString() + "onContentChanged: ", event);
  }
  onEditorBlur(event:any) {
    if(this.bas.envtest) console.log(MiscUtil.getTimeString() + ", onEditorBlur: ", event, ", control: ", this.control);
    // console.log("form.value: ", this.control.fc?.value)

    this.emitChange(event, undefined, this.control.fc?.value ); //TODO:target
  }

  onNumberRangeChange(idx:number, event:any) {
    // console.log("onNumberRangeChange: " + idx + ", e: ", event);
    

    // console.log("control.value: ", this.control.value);
  

    if (this.control.fc) {
      this.control.fc.value[idx] = event.target.value;
      if(this.bas.envtest) console.log("fc.value: ", this.control.fc?.value);
      this.emitChange(event, undefined, this.control.fc.value);//TODO:target
    }
  }

  async onAutocompleteInput(event: any, control:DynformControl) {
    // let value = (event.target as HTMLInputElement).value;
    // if (value) value = value.toLowerCase();
   
    // if(this.bas.envtest) console.log("onAutocompleteInput: ", event);
    let value = event.toLowerCase();
   
    control.data.autocomplete = control.data.autocomplete || { };
    let ac = control.data.autocomplete

    let optionsAll:any[] = (ac.optionsAll || []);

    if (ac.type && value != "") {
      if(this.bas.envtest) console.log("onAutocompleteInput, value.next: " + value);
      this.autocompleteTerm.next(value);
      

      
    } else {
      ac.options = value ? optionsAll.filter((opt:any) => {
        let val = opt.value || opt; 
        if (typeof val === "string") val = val.toLowerCase();
        return val.indexOf(value) >= 0;

      }) : optionsAll;

      if(this.bas.envtest) console.log("onAutocompleteInput, value: " + value + "; optionsAll: " + optionsAll.length + "; res: " + ac.options.length);

    }

    // let firstOpt = opts.length ? opts[0] : { };
    // if (typeof firstOpt === "string") {
    //   opts = opts.map((str:string) => { return {   }})
    // }



    this.onChange(event);
  }

  onSliderInputChange(event:any) {
    // console.log("onSliderInputChange, event: ", event);

    this.control.fc!.setValue(event)
  }

  onSliderRangeInputChange(index:number, event:any) {
    let fc = this.control.fc!;
    let value = fc.value;
    if (value === undefined) value = [ this.control.data.min || 0, this.control.data.max || 1000 ];
    // if (value.length >= index) value = [0, 0];
    value[index] = event;
    if(this.bas.envtest) console.log("onSliderInputChange, event: ", event, ", value: ", value);
    
    fc.setValue(value)
  }


  pickerDisabledDate = (date:Date):boolean => {
    if (this.control === undefined) return false;
    // console.log("pickerDisabledDate, date: " + date + ", c: ", this.control);

    let data = this.control.data;
    if (data.disabledDate) {
      return data.disabledDate(date);
    }

    if (data.firstDate !== undefined) {
      let firstDate:Date = data.firstDate;
      // console.log("pickerDisabledDate, date: ", date, ", firstDate: ", firstDate, ", date < firstDate: " + (date.getTime() < firstDate.getTime()));
      if (date.getTime() < firstDate.getTime()) return true;
    }


    return false;
  }

  onKeyUp(event:any) {
    let ctl = this.control;
    let ct = ctl.controlType;

    if (!ctl.data.triggerChangeOnKeyup) return; 

    let fv =  this.findValue(event);

    if(this.bas.envtest) console.log("onKeyUp, fv: " + fv + ", ev: ", event);


    this.emitChange(event); 
  }

  onChange(event:any, target?:any) {

    let ctl = this.control;
    let ct = ctl.controlType;
    let fv =  this.findValue(event, target);
    if (ct == "textarea") {
      if (ctl.data.json) {
        let pretty = this.bas.us.pretty(fv);
        if(this.bas.envtest) console.log("onChange.textarea, fv: " + fv + ", pretty: " + pretty);
        if (fv != pretty) {
          ctl.fc?.setValue(pretty);
        }
      }
    }

   
    this.emitChange(event, target); // .target?.value
  }

  onCheckboxListChange(event:any) {
    if(this.bas.envtest) console.log("onCheckboxListChange: ", event);
    // console.log("onCheckboxListChange, control.fc: ", this.control);
    
    this.control.fc?.setValue(event);
    this.onModelChange(event);

    if(this.bas.envtest) console.log("onCheckboxListChange, control.fc.value: ", this.control.fc?.value);
    
  }

  onModelChange(event:any, target?:any) {
    if(this.bas.envtest) console.log("onModelChange: ", event, ", target: ", target);
    if (this.control.type === "number" && typeof event === "string" && event.length > 0) {
      
    }
    if (this.control.controlType === "slider") {
      //console.log("onModelChange, event: ", event);
      this.control.data.sliderInput = event;
    }
    else if (this.control.controlType === "slider-range") {
      //console.log("onModelChange, event: ", event);
      this.control.data.sliderInputMin = event[0];
      this.control.data.sliderInputMax = event[1];
    }


   this.emitChange(event, target);
  }

  findValue(event:any, target:any = undefined) {

    let  value = event 
        && typeof event === "object" 
        && event.target
        ? event.target?.value 
        : event;
    

    return value;


  }

  emitChange(event:any, target:any = undefined, value:any = undefined) {
    if (value === undefined) {
      value = this.findValue(event, target);
    }

    if (target == null && event && typeof event === "object" && event.target) target = event.target;

    let c = this.control;

    // console.log("emitChange, key: " + this.control.key 
    //   + ", value: " + c.value + " -> " + value + " | EQ: " +  (c.value === value) )
    if (c.value === value) {
      return;
    }

    let pv = c.value;
    c.value = value;

    c.onChange({ 
      control: c, 
      event: event, 
      prevValue: pv, 
      value: value,
      target: target,
    });

    // console.log("key: " + c.key + ", c.updateSourceOnChange: " + c.updateSourceOnChange + ", c.source: " + c.source);
    if (c.updateSourceOnChange && c.source) {
      if(this.bas.envtest) console.log("updateSourceOnChange, key: " + c.key + ", c.valuePath: " + c.valuePath + ", c.value: " + MiscUtil.getOwnNestedProperty(c.source, c.valuePath) + " -> " + c.value);
      MiscUtil.setOwnNestedProperty(c.source, c.valuePath, value);
    }

    this.change.emit({ 
      control: c, 
      event: event,
      value: value
    });
  }

  weekdayCheckChange(val:boolean, wd:number) {
    if (this.control.disabled) return;
    let map = this.control.fc?.value;
    if(this.bas.envtest) console.log("weekdayCheckChange, wd: " + wd + ", val: " + val + ", curr.val: ", map);
    
    if (wd === -1) {
      //all
      for (let i = 1; i <= 7; i++) map[i] = val;
    } else {
      map[wd] = val;

    }
 
    this.control.fc?.setValue(map);

  }
  isAllWeekdays(map:any) {
    if (map === undefined) {
      if(this.bas.envtest) console.log("isAllWeekdays, map == undefined, control: ", this.control );
      if(this.bas.envtest) console.log("isAllWeekdays, map == undefined, control.fc: ", this.control.fc );
      if(this.bas.envtest) console.log("isAllWeekdays, map == undefined, control.fc.value: ", this.control.fc?.value );
      return true;
    }
    for (let i = 1; i <= 7; i++) {
      if (map[i] !== true) return false;
    }
    return true;
  }

  boolMapCheckChange(val:boolean, key:any) {
    let map = this.control.fc?.value;
    if(this.bas.envtest) console.log("boolMapCheckChange, key: " + key + ", val: " + val + ", curr.val: ", map);
    
    if (key === -1) {
      //all
      for (let key of Object.keys(map)) map[key] = val;
    } else {
      map[key] = val;

    }
 
    this.control.fc?.setValue(map);

  }
  isAllBoolMap(map:any) {
    for (let val of Object.values(map)) {
      if (val !== true) return false;
    }
    return true;
  }

  tagsIsAllSelected() {
    let ctrl = this.control;
    let map = this.control.fc?.value || { };
    let opts = ctrl.options();
    for (let opt of opts) {
      if (map[opt[ctrl.optionsFieldValue]] !== true) return false;
    }
    return true;
  }
  tagsIsSelected(opt:any) {
    let ctrl = this.control;
    let map = ctrl.fc?.value || { };
    return map[opt[ctrl.optionsFieldValue]];
  }
  tagsChange(val:boolean, value:any) {
    let ctrl = this.control;
    let map = ctrl.fc?.value || { };
    let opts = ctrl.options();
    if (value === -1) {
      for (let opt of opts) {
        map[opt[ctrl.optionsFieldValue]] = val;
      }
    } else {
      map[value[ctrl.optionsFieldValue]] = true;
    }
    this.control.fc?.setValue(map);

  }

  // Er ikke i bruk
  numberPrecisionMode = (value: number | string, precision?: number): number => {
    let num = this.numberParser(value + "");
    //console.log("val: " + value + ", num: " + num + ", precision: " + precision);
    return parseFloat(num);
  }

  numberFormatter = (value: number): string => {
    let data = this.control.data;
    if (value === undefined) return "";
    //console.log("numberFormatter: ", value);
    if (data.numberFormatter === false) return value + "";

    if (data.numberFormatter && typeof data.numberFormatter === "function") return data.numberFormatter(value);

    if (data.suffix) return value + " " + data.suffix;

    if (data.fractionSize) {
      return this.bas.ui.nf(value, data.fractionSize);
    }
    if (value > 1000)  return this.bas.ui.nf(value, 0);
    return `${value}`;
  }
  numberParser = (value: string): string => {
    let data = this.control.data;
    if (value === undefined) return "";
    if (data.numberParser && typeof data.numberParser === "function") return data.numberParser(value);

    if (data.suffix) return value.replace(" " + data.suffix, '');


    // return value;
    if (!data.fractionSize) return value;

    value = value.replace(this.bas.ui.DECIMAL_SEPARATOR, ".");

    return value;
    //   if (value.endsWith(this.bas.ui.DECIMAL_SEPARATOR)) return value;
    
    // let parsed = this.bas.ui.nfparse(value, data.fractionSize || 0) + "";
    // // if (value > 1000)  this.bas.ui.nfparse(value, data.fractionSize) + "";
    
    
    // console.log("numberParser, data.fractionSize: "+data.fractionSize+", val: ", value, ", parsed: ", parsed);

    // return parsed;
  }


  uploadUrl() {
    // extraParams
    let url = this.bas.ws.getBaseUrlTools() 
      + '?action=uploadDropzone&type='+this.control.data.upload?.type
      +'&uuid=' + this.control.data.upload?.uuid
    ;

    if (this.control.data.upload.extraParams) {
      url += "&" + this.bas.ws.getParamsAsString(this.control.data.upload.extraParams);
    }
    return url;
  }

  uploadHeaders = (file: NzUploadFile): Object => {
    let headers = this.bas.ws.getCommonHeaders();
    //MiscUtil.extend(headers, this.bas.ws.getLoginHeaders());
    return headers;
  }
  handleUploadFile({ file, fileList, event }: NzUploadChangeParam, control:any): void {

    if (this.bas.envtest) console.log('handleUploadFile file: ', file, ", event: ", event);

    const status = file.status;
    const resp = file.response;

    
    let val = fileList.map((file) => file.filename || file.name).join(";");


      
    this.control.fc?.setValue(val);
    this.onModelChange(val);

    if (status !== 'uploading') {
      if (this.bas.envtest) console.log(file, fileList);

      if (status == "removed") {
        let itemId = resp.itemId;

        this.bas.ws.json({ 
          // checkChildApp: true,
          aType: "appService", 
          action: "removeUploadedFile", 
          type: control.data.upload.type,
          itemId: itemId
        
        }).then((json) => {
          if (this.bas.envtest) console.log("removeUploadedFile, json: ", json);
          if (json.success) {
          
          }
        });

      }
    }
    if (status === 'done') {
     
      

      if (this.bas.envtest) console.log(file.name + 'file uploaded successfully, resp: ', resp);

      if (resp.itemId) {
        //TODO
      } else {
        this.bas.ui.error(this.bas.ui.actrans("app.lib.common.error.upload.general" ));
      }


    } else if (status === 'error') {
      if (this.bas.envtest) console.log(`${file.name} file upload failed.`);
      // TODO
      // this.bas.ui.error(this.bas.ui.actrans("common.error.imageUpload", [], false, "En feil oppstod da vi skulle behandle filen du lastet opp. Vanligvis er dette fordi du har lastet opp en JPEG fil i CMYK-format. Kun JPEG bilder i RGB-format kan lastes opp")); //TODO:text
      
    }
  }


  mbscDatepickerInvalid:Date[] = []

  mbscDatepickerOnPageLoading(event:any) {

    let inst = event.inst;
    let fd = event.firstDay;
    let ld = event.lastDay;


    let invalid:any[] = [];
    
    let data = this.control.data;
    if (!data.selectableDates) return;

    let dates:Date[] = this.bas.ui.getAllDatesBwtweenDates(fd, ld);

    for (let current of dates) {
      let dateInt = MiscUtil.getDateAsInt(current);
      
      if (data.selectableDates) {
        if (MiscUtil.binarySearch(data.selectableDates, dateInt) < 0) {
 
          invalid.push(current);
        }
      }
    }
    this.mbscDatepickerInvalid = invalid;
  }

  mbscDatepickerOnChange(event:any) {

  }

}



