import { Component, Injector, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { BtFormRowComponent } from "../../form/bt-form-row/bt-form-row.component";

@Component({
  selector: 'bt-user-delete-user',
  standalone: true,
  imports: [ 
    LibModulesWolcModule,
    BtFormRowComponent,
   ],
  templateUrl: './bt-user-delete-user.component.html',
  styleUrl: './bt-user-delete-user.component.scss'
})
export class BtUserDeleteUserComponent {
  bas = inject(BooktechAppService);

  @Input() 
  get user(): any { return this.edit.obj; }
  set user(user: any) {
    // console.log("user.set: ", user);
    if (user == undefined) return;
    this.editObject(user);
  }

  @Output() userChange = new EventEmitter<any>();


  edit:any = {
    visible: false,
    obj: undefined,
   
  }
  
  constructor() {

    if(this.bas.envtest) console.log("BtUserDeleteUserComponent");

  }

  ngOnInit(): void {

  }

  editObject(obj:any) {

    if(this.bas.envtest) console.log("editObject, obj: ", obj);

    let isAdmin = this.bas.aas.isAdmin();

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ 
      key: 'userId',
      valuePath: 'id' 
    }));

    controls.push(new DynformControl({ 
      key: 'confirmDelete', 
      mk: 'web.common.user.confirmDeleteDesc', 
      controlType: "checkbox",
      required: true
    }));

    if (isAdmin) {
      controls.push(new DynformControl({ 
        key: 'force', 
        mk: 'web.common.user.forceDeleteDesc', 
        controlType: "checkbox"
      }));
    }





    this.bas.fs.updateFormObj(this.edit, controls, obj);
    // this.edit.visible = true;
  } 

  onChange(event:any) {

  }

  onCancel() {
    // this.edit.visible = false;
    this.edit.obj = undefined;
    this.userChange.next(this.edit.obj);
  }
  onOk() {

    let rv = this.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    

    let obj = this.edit.obj;

    let params:any = {
      aType: "appAdmin", 
      action: "deleteUser"
    };
    MiscUtil.extend(params, rv);


    if(this.bas.envtest) console.log("onOk, params: ", params);


    this.edit.loading = true;
    this.bas.ws.json(params).then((json) => {
      this.edit.loading = false;
      if (json.success) {
        this.bas.ui.success(json.statusDesc); 

        this.userChange.next(this.edit.obj);
        
        this.edit.obj = undefined;
      }
    });
    

    // this.edit.visible = false;
  }



}
