// /* angular2-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
// /* https://raw.githubusercontent.com/urish/angular2-moment/issue-48/src/TimeAgoPipe.ts */


import {Pipe, ChangeDetectorRef, PipeTransform, OnDestroy, inject} from '@angular/core';
import { BooktechAppService } from "../services/booktech-app.service";


@Pipe({ name: 'amTimeAgo', pure: false, standalone: true })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  bas = inject(BooktechAppService);

  private _currentTimer?: number;
  private _lastValue?: Date | string
  private _lastTimeAgo?: string;

  constructor( private _cdRef: ChangeDetectorRef) {
  }

  transform(value: Date | string, omitSuffix?: boolean): string {
  //   return "TODO"
  // }
  // transform(value: Date | string | moment.Moment, omitSuffix?: boolean): string {
  //   const momentInstance = typeof value == "string" ? moment.utc(value) : momentConstructor(value);
    let date = typeof value == "string" ? this.bas.ui.dateParseIso(value) : value;
    this._removeTimer();

    // const timeToUpdate = this._getSecondsUntilUpdate(momentInstance) * 1000;
    // this._currentTimer = window.setTimeout(() => {
    //   this._lastValue = null;
    //   this._cdRef.markForCheck();
    // }, timeToUpdate);

    // We keep track of the last value in order to avoid `Expression has changed after it was checked`
    // See https://github.com/urish/angular2-moment/issues/48 for details.
    if (this._lastValue !== value) {
      this._lastValue = value;
      this._lastTimeAgo =  this.bas.ui.formatDistanceToNow(date); // momentInstance.from(momentConstructor(), omitSuffix);
    }

    return this._lastTimeAgo || "";
  }

  ngOnDestroy(): void {
    this._removeTimer();
  }

  _removeTimer() {
    if (this._currentTimer) {
      window.clearTimeout(this._currentTimer);
      this._currentTimer = undefined;
    }
  }

  _getSecondsUntilUpdate(date: Date) {
    const howOld = Math.abs(this.bas.ui.dateDifference(date, new Date(), 'minute'));
    if (howOld < 1) {
      return 1;
    } else if (howOld < 60) {
      return 30;
    } else if (howOld < 180) {
      return 300;
    } else {
      return 3600;
    }
  }
}
