import { Component, Injector, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { BtFormRowComponent } from "../../form/bt-form-row/bt-form-row.component";

@Component({
  selector: 'bt-user-change-username',
  standalone: true,
  imports: [ 
    LibModulesWolcModule,
    BtFormRowComponent,
   ],
  templateUrl: './bt-user-change-username.component.html',
  styleUrl: './bt-user-change-username.component.scss'
})
export class BtUserChangeUsernameComponent {
  bas = inject(BooktechAppService);

  @Input() 
  get user(): any { return this.edit.obj; }
  set user(user: any) {
    // console.log("user.set: ", user);
    if (user == undefined) return;
    this.editObject(user);
  }

  @Output() userChange = new EventEmitter<any>();


  edit:any = {
    visible: false,
    obj: undefined,
   
  }
  
  constructor() {

    if(this.bas.envtest) console.log("BtUserChangeUsernameComponent");

  }

  ngOnInit(): void {

  }

  editObject(obj:any) {

    if(this.bas.envtest) console.log("editObject, obj: ", obj);

    let isAdmin = this.bas.aas.isAdmin();

    let controls: DynformControl[] = [];

    controls.push(new DynformControl({ key: 'id' }));

    controls.push(new DynformControl({ 
      key: 'username', 
      valuePath: 'empty',
      mk: 'web.common.user.changeUsername.username', 
      required: true
    }));
    controls.push(new DynformControl({ 
      key: 'usernameConfirm', 
      mk: 'web.common.user.changeUsername.usernameConfirm', 
      required: true
    }));




    this.bas.fs.updateFormObj(this.edit, controls, obj);
    // this.edit.visible = true;
  } 

  onChange(event:any) {

  }

  onCancel() {
    // this.edit.visible = false;
    this.edit.obj = undefined;
    this.userChange.next(this.edit.obj);
  }
  onOk() {

    let rv = this.bas.fs.getRawValue(this.edit.form, this.edit.controls);
    if (rv === false) return;
    
    if (rv.username != rv.usernameConfirm) {
      // TODO: https://stackoverflow.com/a/67232471
      
      this.bas.ui.error(this.bas.ui.actrans("web.main.js.jp.register.emailsDoNotMatch"))
      return;
    }

    let obj = this.edit.obj;

    let params:any = {
      aType: "appAdmin", 
      action: "changeUsername"
    };
    MiscUtil.extend(params, rv);


    if(this.bas.envtest) console.log("onOk, params: ", params);

    this.edit.loading = true;

    this.bas.ws.json(params).then((json) => {
      this.edit.loading = false;
      if (json.success) {
        this.bas.ui.success(json.statusDesc); 

        this.userChange.next(this.edit.obj);
        this.edit.obj = undefined;
      }
    });
    
    // this.edit.visible = false;
  }



}
