import { inject, Injectable } from '@angular/core';
// import { AppEvent, AppService } from 'src/app/services/app.service';

declare let klaro: any;
// @ts-ignore // TODO
// import * as Klaro from 'klaro'
// import { BooktechAppService } from '@btlib-core';
// import { BtEvent } from '@btlib-core';
import { BooktechAppService } from '../../../services/booktech-app.service';
import { BtEvent } from '../../../services/event.service';



// @Injectable({
//   providedIn: 'root'
// })
export class KlaroService {
  // bas = inject();

  klaroSettings:any = {

    config: {
      // testing: true,
      storageMethod: 'localStorage',
      acceptAll: true,

      // lang: "no",

      translations: {
        no: {

          // privacyPolicyUrl: '/privacy', // TODO:
          consentModal: {
              description:
                  'Her kan du se og velge hvilken informasjon vi samler inn om deg.',
          
          },
          privacyPolicy: {
            text: '{privacyPolicy}.',
            name: 'Personvernerklæring',
          },
          consentNotice: {
            testing: "TESTING",
            // consentNotice:
            changeDescription: 'Det har skjedd endringer siden ditt siste besøk, vennligst oppdater ditt samtykke.',
            // description: 'Vi samler inn og prosesserer din personlige informasjon av følgende årsaker: {purposes}.',
            description: 'Hei! Kan vi aktivere noen tilleggstjenester for {purposes}? Du kan alltid endre eller trekke tilbake samtykket ditt senere'
          },
          purposeItem: {
            service: "tjeneste",
            services: "tjenester"
          },

          acceptAll: "Godta alle",
          acceptSelected: "Godta valgte",
          save: "Lagre",

          service: {
            disableAll: {
              description: "Bruk denne for å skru av/på alle tjenester.",
              title: "Skru av/på alle tjenester"
            }
    
          },

          // poweredBy: '', // Bytte ut "Realized with Klaro!"
          // ok: 'Wohoo!',

          purposes: {
            core: { title:  'Grunnleggende funksjonalitet' },
            analytics:  { title: 'Analyse' },
            marketing: { title:  'Markedsføring' },
          },
          
          googleAnalytics:  { title:  'Analyse' },
        },
        en: {
          // privacyPolicyUrl: '/privacy',
          consentModal: {
              description:
                  'Here you can see and customize the information that we collect about you. ' 
                  ,
              privacyPolicy: {
                text: 'This is the text with a link to your {privacyPolicy}.',
                name: 'privacy policy (the name)',
            },
          },

          purposes: {
            core: { title:  'Basic functionality' },
            analytics: { title:  'Analytics' },
            marketing: { title:  'Marketing' },
            testing: { title:  'Testing' },
          },

          googleAnalytics:  { title:  'Analytics' },
        },
      },
      services: [
        {
          name: 'core',

          purposes: ['core'],
          
          default: true,
          required: true,

          translations: {
            "no": {
              title: 'Grunnleggende funksjonalitet',
              description: 'Disse dataene er påkrevd for å bruke nettstedet, og inkluderer blant annet innholdet i handlevognen, innlogging og annen grunnleggende informasjon.',
              
            }, 
            "en": {
              title: 'Basic functionality',
              description: 'This data is required to use the website and includes, among other things, the contents of the shopping cart, login and other basic information. ',
              
            }
          }
        },
        
        {
          name: 'analytics',
          
          default: true,
          purposes: ['analytics'],
          translations: {
            "no": {
              title: 'Ytelse og analyse',
              description: 'Disse dataene gjør at vi kan telle besøk og trafikkilder, slik at vi kan måle og forbedre ytelsen til nettstedet vårt. De hjelper oss å vite hvilke sider som er de mest og minst populære og se hvordan besøkende bruker nettstedet. All informasjonen som disse informasjonskapslene innhenter, blir aggregert og derfor anonym. Hvis du ikke tillater disse informasjonskapslene, vet vi ikke når du har besøkt nettstedet vårt.',
            }, 
            "en": {
              title: 'performance and analytics',
              description: 'This data allows us to count visits and traffic sources so that we can measure and improve the performance of our website. They help us know which pages are the most and least popular and see how visitors use the site. All the information that these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our website.',
          
            }
          }
        },
        {
          name: 'marketing',
          default: false,
          purposes: ['analytics', 'marketing'],
          translations: {
            "no": {
              title: 'Intern oppfølging og markedsføring',
              description: 'Disse dataene er knyttet til deg som person og brukes for å gi deg relevant oppfølging og markedsføring. ',
            }, 
            "en": {
              title: 'Internal follow-up and marketing',
              description: 'This data is linked to you as a person and is used to provide you with relevant follow-up and marketing.',
          
            }
          }
        },
      
      ],

      callback: (consent:boolean, service:any) => {
        this.onClose(consent, service);
      },
    },
    services: {
      // googleAnalytics: {
      //   name: 'googleAnalytics',
      //   purposes: ['analytics', 'marketing'],
      //   translations: {
      //     "no": {
      //       title: 'Google Analytics',
      //       description: 'Dette nettstedet bruker Google Analytics, en tjeneste for webanalyse levert av Google Inc. ("Google"). Google Analytics bruker såkalte informasjonskapsler – tekstfiler som er lagret på datamaskinen og som aktiverer en analyse av din bruk av nettstedet. Normalt vil informasjon om din bruk av denne nettsiden som genereres av informasjonskapselen bli lagret på en Google-server i USA. Men hvis du aktiverer IP-anonymisering på dette nettstedet, vil Google først forkorte IP-adressen din innenfor medlemslandene i EU eller i andre land som har underskrevet EØS-avtalen. Bare i unntakstilfeller vil Google overføre full IP-adresse til en Google-server i USA og forkorte den der. På vegne av operatøren av dette nettstedet, bruker Google denne informasjonen til å analysere bruken din av nettstedet, for å samle rapporter om nettstedets aktiviteter, og for å tilby andre tjenester til operatøren av nettstedet i forbindelse med bruk av nettstedet og Internett.'
      //       + 'IP-adressen som overføres av nettleseren din til Google Analytics er ikke forbundet med noen andre data som Google er i besittelse av.'
      //       + 'Du kan velge innstillingene i nettleserens programvare for å forhindre av informasjonskapsler lagres for Google Analytics. Videre kan du forhindre at data som er generert av informasjonskapsler og som er relatert til din bruk av nettstedet blir overført til Google (inkl. IP-adressen din) samt behandlingen av disse opplysningene av Google ved å laste ned og installere en programvareutvidelse på nettleseren'
      //       + 'Vi bruker Google Analytics til å analysere bruken av nettsiden vår, slik at vi jevnlig kan gjøre den bedre. I helt spesielle saker, hvor personopplysninger overføres til USA har Google forpliktet seg i henhold til EU-US Privacy Shield https://www.privacyshield.gov/EU-US-Framework. Det juridiske grunnlaget for bruk av Google Analytics er art. 6, avs. 1 p. 1 lit. f personvernforordningen.',
      //     }, 
      //     "en": {
      //       title: 'Google Analytics',
      //       description: '',
        
      //     }
      //   }
      // },
      gtm: {
        name: 'google-tag-manager',
        required: true,
        purposes: ['marketing'],
        onAccept: `
            // we notify the tag manager about all services that were accepted. You can define
            // a custom event in GTM to load the service if consent was given.
            for(let k of Object.keys(opts.consents)){
                if (opts.consents[k]){
                    let eventName = 'klaro-'+k+'-accepted'
                    dataLayer.push({'event': eventName})
                }
            }
            console.log('gtm.onAccept', dataLayer);
        `,
        onInit: `
            // initialization code here (will be executed only once per page-load)
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){dataLayer.push(arguments)}
            gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied'})
            gtag('set', 'ads_data_redaction', true)
        `,
    },
    googleAnalytics: {
        // In GTM, you should define a custom event trigger named `klaro-google-analytics-accepted` which should trigger the Google Analytics integration.
        name: 'google-analytics',
        // default: true,
        cookies: [
            /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
        ],
        purposes: ['marketing'],
        onAccept: `
            // we grant analytics storage
            gtag('consent', 'update', {
                'analytics_storage': 'granted',
            })
        `,
        onDecline: `
            // we deny analytics storage
            gtag('consent', 'update', {
                'analytics_storage': 'denied',
            })
        `,
    },
    googleAds: {
        name: 'google-ads',
        cookies: [],
        onAccept: `
            // we grant ad storage and personalization
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted'
            })
        `,
        onDecline: `
            // we decline ad storage and personalization
            gtag('consent', 'update', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied'
            })
        `,
        purposes: ['marketing'],
      },
      test: {
        name: 'test',
        purposes: ['testing'],
        translations: {
          "no": {
            title: 'Testing NORSK',
            description: 'Testing NORSK',
          }, 
          "en": {
            title: 'Testing ENG',
            description: 'Engelsk ',
        
          }
        }
      },
    }
  };

  services:any = {

  }


  constructor(public bas:BooktechAppService) {
    if (this.bas.envtest) console.log("KlaroService.constructor");

    this.loadKlaro();
    return;
      

   
  }


  loadKlaro() {

    this.bas.script.loadScript({ name: "klarojs", src: "https://cdn.kiprotect.com/klaro/v0.7/klaro.js", id: "klarojs" }).then((res:any) => {
      // if (this.bas.envtest) console.log("KlaroService.loadKlaro, res: ", res);
      // if (this.bas.envtest) console.log("KlaroService.loadKlaro, klaro: ", klaro);
      if (res.loaded) {

        this.afterLoad();
      }
      
    })
  }

  afterLoad() {
    // (<any>window).klaro = Klaro;
    // (<any>window).klaroConfig = klaroConfig;

    // this.bas.es.on(BtEvent.DS_INIT, () => {
      let fc = this.bas.ds.findCompany(true);

      let kc = this.klaroSettings.config;

      // kc.translations.no.privacyPolicyUrl = ""; // Dette er ikke påkrevd
  
    //   kc.callback =  (consent:boolean, service:any) => {
    //     console.log(
    //         'constructor.User consent for service ' + service.name + ': consent=' + consent
    //     );
    //   },
  
      if (fc) { // Denne kan være null
        if (this.bas.envtest)  console.log("klaro.fc: ", fc)
        let gtmId = this.bas.envtest ? fc.gtmIdTest :  fc.gtmIdProd;
        if (!gtmId && this.bas.envtest) gtmId = "GTM-M543MJMQ";

        let gtmServices = fc.gtmServices || { }

        if (gtmId) {
          kc.services.push(this.klaroSettings.services.gtm);

          for (let key of Object.keys(gtmServices)) {
            if (gtmServices[key].enabled && this.klaroSettings.services[key]) {
              kc.services.push(this.klaroSettings.services[key]);
            }
          }
        } 


        kc.lang = this.bas.ds.findLang() || "no";
      }
  
  
      if (this.bas.envtest) console.log("KlaroService.constructor, klaro: ", klaro);
      if (this.bas.envtest) console.log("KlaroService.constructor, kc: ", kc);
      let res = klaro.setup(kc);
      if (this.bas.envtest) console.log("KlaroService.constructor, setup.res: ", res);
    // });
   
  }

  onClose(consent:boolean, service:any) {
    if (this.bas.envtest)  {
      this.services[service.name] = consent;

      this.bas.es.trigger(BtEvent.KlaroConsentUpdate, { service: service, consent: consent });
      console.log(
        'global.User consent for service ' + service.name + ': consent=' + consent
      );
    }
   
  }


  openModal() {
    klaro.show();
  }


}
